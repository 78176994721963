<template>
  <div class=" container-fluid container-fixed-lg bg-white app-table-container">
    <Modal modal-class="slide-up" ref="modal_register" size="lg"
           :modal-header-title="$t('taxi.register available')">
      <template v-slot:container>
        <ValidationObserver ref="form_update_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(registerAvailableRequest)">
            <div class="modal-body">
              <AppRadio
                :radio-inputs="[
                  {label: $t('taxi.taxi available type 1'), value: 1},
                  {label:$t('taxi.taxi available type 2'), value: 2}
                 ]" name="TO_plan" :label="$t('taxi.taxi available type')"
                rules="required"
                disabled="true"
                v-model="entryToRegister.type"></AppRadio>
              <AppDatePicker name="date" :label="$t('taxi.taxi available date')"
                             :options="{startDate: new Date()}"
                             disabled="true"
                             v-model="entryToRegister.date"/>
              <AppSelect v-if="entryToRegister.type == 1" :options="{placeholder: $t('common.taxi_type')}"
                         name="taxi_type"
                         disabled="true"
                         :label="$t('common.taxi_type')" rules="required"
                         :options-data="taxiTypesSelectTable"
                         v-model="entryToRegister.taxi_type_id">
              </AppSelect>
              <AppInput type="int" :rules="'required|taxi_available:' + entryToRegister.cars_busy" name="TO_price"
                        :label="$t('taxi.cars num')"
                        v-model="entryToRegister.cars_num"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <div class="card card-transparent">
      <div class="card-header search-container">
        <div class="search-content">
          <ValidationObserver ref="form_search" v-slot="{ passes, valid, validated }">
            <form novalidate autocomplete="off" @submit.prevent="passes(searchHandle)">
              <div class="row justify-content-around">
                <div class="col-lg-3 col-md-4 col-sm-6">
                  <AppSelect :options="{containerCssClass: 'z-index-0', placeholder: $t('taxi.taxi company name')}"
                             input-style="normal"
                             :name="filters[1].name"
                             :label="filters[0].label"
                             label-class="text-complete"
                             :onChange="getTaxiTypes"
                             :options-data="filters[0].options"
                             v-model="filters[0].value"/>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6">
                  <AppSelect :options="{containerCssClass: 'z-index-0', placeholder: $t('taxi.taxi type')}"
                             input-style="normal" :name="filters[1].name"
                             :label="filters[1].label"
                             label-class="text-complete"
                             :options-data="filters[1].options"
                             v-model="filters[1].value"/>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6">
                  <AppDatePicker input-style="normal" :name="filters[2].name"
                                 label-class="text-complete"
                                 :label="filters[2].label"
                                 :options="{
                                    format: 'yyyy/mm',
                                    startView: 'months',
                                    minViewMode: 'months'
                                 }"
                                 v-model="filters[2].value"/>
                </div>
              </div>
              <div class="m-t-20 row justify-content-center">
                <button @click="resetAllSearch"
                        class="btn btn-default m-r-10" type="button">
                  {{ $t('common.reset') }}
                </button>
                <button :disabled="!valid && validated" class="btn btn-default text-complete m-r-10" type="submit">
                  {{ $t('common.search') }}
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <div class="card-body">
        <div class="row dataTables_wrapper no-footer bg-white justify-content-md-start">
          <div style="width: 250px;">
            <table :class="['table dataTable app-table no-footer table_available_taxi table-left-fixed']">
              <tbody>
              <tr class="app-text-center">
                <th class="min-w-40 app-text-center app-text-bold b-b-none" colspan="2">
                  <span class="visible-none">hidden</span>
                </th>
              </tr>
              <tr class="app-text-center">
                <th class="min-w-40 app-text-center app-text-bold b-t-none b-b-none"
                    colspan="2">
                  {{ $t('taxi.taxi type label') }}
                </th>
              </tr>
              <tr class="app-text-center">
                <th class="min-w-40 app-text-center app-text-bold b-t-none" colspan="2">
                  <span class="visible-none">hidden</span>
                </th>
              </tr>
              <template v-for="entry in entries">
                <tr>
                  <th class="v-align-middle width-70 max-w-70" rowspan="3">
                    <span class="taxi_type_name" data-placement="right" :data-original-title="entry.name">{{
                        entry.name
                      }}</span></th>
                  <th class="min-w-30 width-30">{{ $t('taxi.available label 1') }}</th>
                </tr>
                <tr>
                  <th class="min-w-30 width-30">{{ $t('taxi.available label 2') }}</th>
                </tr>
                <tr>
                  <th class="min-w-30 width-30">{{ $t('taxi.available label 3') }}</th>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
          <div style="width: calc(100% - 250px)">
            <div class="table-responsive sm-m-b-15">
              <table :class="['table dataTable app-table no-footer table_available_taxi']">
                <tbody>
                <tr class="app-text-center">
                  <th class="app-text-center app-text-bold" :colspan="meta.days">
                    {{ `${meta.year}-${meta.month}` }}
                  </th>
                </tr>
                <tr>
                  <th :class="[
                  'app-text-center app-text-bold',
                  isOldDate(day) ?  'disabled' : '',
                     isSameDate(day) ? 'highlight' : '',
                ]"
                      v-for="day in meta.days">
                    {{ ("0" + day).slice(-2) }}
                  </th>
                </tr>
                <tr>
                  <th
                    :class="[
                    'app-text-center app-text-bold',
                     isOldDate(day) ?  'disabled' : '',
                     isSameDate(day) ? 'highlight' : '',
                     dayData(day).code == 0 ? 'text-danger' : (dayData(day).code == 6 ? 'text-complete' : '')]"
                    v-for="day in meta.days">
                    {{ dayData(day).text }}
                  </th>
                </tr>
                <template v-for="entry in entries">
                  <tr>
                    <td
                      @click="registerAvailableHandle(entry.id, entry.type ,available.cars_num, date, available.cars_busy)"
                      :class="['app-text-center available-regis-btn', isOldDateString(date) ?  'disabled' : '']"
                      v-for="(available, date) in entry.availables">
                      {{ available.cars_num }}
                    </td>
                  </tr>
                  <tr>
                    <td :class="['app-text-center',  isOldDateString(date) ?  'disabled' : '']"
                        v-for="(available, date) in entry.availables">
                      {{ available.cars_busy }}
                    </td>
                  </tr>
                  <tr>
                    <td :class="[
                      'app-text-center',
                      isOldDateString(date) ?  'disabled' : '',
                      available.cars_available < 1 ? 'bg-active' : '',
                    ]"
                        v-for="(available, date) in entry.availables">
                      {{ available.cars_available }}
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const EVENT_SEARCH = 'EVENT_SEARCH';

export default {
  data() {
    var filters = [
      {
        name: 'taxi_company_id',
        label: this.$t('taxi.taxi company name'),
        type: 'equal',
      },
      {
        name: 'taxi_type_id',
        label: this.$t('taxi.taxi type'),
        type: 'equal',
      },
      {
        name: 'date',
        label: this.$t('taxi.date month'),
        type: 'month_year_equal',
      }
    ];
    _.forEach(this.$route.query, (value, columnString) => {
      if (_.includes(columnString, 'filters.')) {
        let splited = columnString.split('.');
        let name = splited[1];
        let type = splited[2];
        _.forEach(filters, (filter, index) => {
          if (filter.name === name && filter.type === type) {
            if (value && value.toString().length) {
            }
            filters[index] = {...filters[index], value: value}
          }
        })
      }
    })
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    if (!filters[2].value) {
      filters[2].value = year + '/' + ("0" + month).slice(-2)
    }
    return {
      entryToRegister: {},
      entries: [],
      taxiTypes: [],
      listTaxiCompanies: [],
      meta: {
        month: ("0" + month).slice(-2),
        year: year,
        days: (new Date(year, month, 0)).getDate(),
      },
      filters: filters,
    }
  },
  computed: {
    today() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const date = now.getDate();
      return {
        obj: now,
        date: date,
        month: ("0" + month).slice(-2),
        year: year,
      }
    },
    taxiTypesSelectTable() {
      return this.meta.taxiTypes.length ? this.meta.taxiTypes : this.taxiTypes;
    }
  },
  watch: {
    "$route.query": {
      handler: function (after, before) {
        if (!_.isEqual(after, before)) {
          this.setSearchStateByRouteQuery()
          this.getEntries()
        }
      },
      deep: true,
    }
  },
  mounted() {
    this.getEntries()
    this.$request.get(this.$consts.API.CS.TAXI_LIST_SELECT).then(res => {
      if (!res.hasErrors()) {
        this.listTaxiCompanies = res.data.data
        this.filters = this.getOptionFilter(this.filters, 'taxi_company_id', 'equal', this.listTaxiCompanies);
      }
    });
  },
  methods: {
    getTaxiTypes(taxiCompanyId) {
      if (parseInt(taxiCompanyId)) {
        this.$request.get(this.$consts.API.CS.TAXI_TYPE_SELECT(parseInt(taxiCompanyId)), null, {noLoadingEffect: true}).then(res => {
          if (!res.hasErrors()) {
            this.taxiTypes = _.clone(res.data.data)
            this.filters = this.getOptionFilter(this.filters, 'taxi_type_id', 'equal', _.clone(res.data.data));
          }
        })
      }
    },
    dayData(day) {
      return this.getDayFromDate(`${this.meta.year}-${this.meta.month}-${day}`)
    },
    registerAvailableRequest() {
      const {type, date, taxi_type_id, cars_num} = this.entryToRegister
      this.$request.post(this.$consts.API.CS.TAXI_AVAILABLE_REGISTER, {
        type, date, taxi_type_id, cars_num, taxi_company_id: this.meta.taxi_company_id
      }).then(res => {
        this.setSearchStateByRouteQuery();
        if (!res.hasErrors()) {
          this.$appNotice.success(this.$t('common.msg update ok'))
          this.$refs.modal_register.hide();
          this.getEntries()
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      })
    },
    registerAvailableHandle(taxiTypeId, type, carsNum, date, cars_busy) {
      if (this.isOldDateString(date)) {
        return;
      }
      this.entryToRegister = {
        type: type,
        date: date,
        taxi_type_id: taxiTypeId,
        cars_num: carsNum,
        cars_busy: cars_busy,
      }
      this.$refs.modal_register.show();
    },
    searchHandle() {
      this.updateRouteQuery([EVENT_SEARCH])
    },
    async getEntries() {
      let params = {}
      const filters = this.filters
      if (!filters[0].value) {
        this.entries = []
        return;
      }
      _.forEach(filters, (filter) => {
        if (filter.value && filter.value.toString().length) {
          params[`filters[${filter.name}][${filter.type}]`] = filter.value
        }
      })
      const res = await this.$request.get(this.$consts.API.CS.TAXI_AVAILABLE, {...params})
      if (!res.hasErrors()) {
        this.entries = res.data.data.available;
        this.meta = res.data.data.meta;
        this.meta.taxi_company_id = filters[0].value
        this.meta.taxiTypes = _.clone(this.taxiTypes)
      } else {
        this.entries = []
      }
      this.$nextTick(() => {
        $.each($('.taxi_type_name'), (index, el) => {
          if (el.offsetHeight < el.scrollHeight) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      });
    },
    setSearchStateByRouteQuery() {
      var newFilters = _.cloneDeep(this.filters)
      _.forEach(newFilters, (filter, index) => {
        newFilters[index] = {...filter, value: null}
      })
      _.forEach(this.$route.query, (value, columnString) => {
        if (_.includes(columnString, 'filters.')) {
          let splited = columnString.split('.');
          let name = splited[1];
          let type = splited[2];
          _.forEach(newFilters, (filter, index) => {
            if (filter.name === name && filter.type === type) {
              newFilters[index] = {...newFilters[index], value: value}
            }
          })
        }
      })
      this.filters = newFilters
    },
    resetAllSearch() {
      this.filters = this.getOptionFilter(this.filters, 'taxi_type_id', 'equal', []);
      if (Object.keys(this.$route.query).length === 0) {
        this.setSearchStateByRouteQuery()
        this.getEntries()
      } else {
        this.$router.push({name: this.$route.name, query: {}})
      }
    },
    updateRouteQuery(events) {
      if (!_.isArray(events)) {
        events = [events]
      }
      var queryObj = _.clone(this.$route.query);
      _.forEach(events, event => {
        if (event === EVENT_SEARCH) {
          const filters = this.filters;
          _.forEach(filters, (filter) => {
            if (filter.value && filter.value.toString().length) {
              queryObj['filters.' + filter.name + '.' + filter.type] = filter.value
            } else {
              delete queryObj['filters.' + filter.name + '.' + filter.type];
            }
          })
        }
      })
      if (!_.isEqual(queryObj, this.$route.query)) {
        this.$router.push({query: queryObj})
      } else {
        this.getEntries()
      }
    },
    isOldDate(day) {
      return (new Date(this.meta.year, this.meta.month, day)).getTime() < new Date(this.today.year, this.today.month, this.today.date).getTime()
    },
    isOldDateString(dateString) {
      let timeNow = new Date(`${this.today.year}-${this.today.month}-${this.today.date}`).getTime();
      return (new Date(dateString)).getTime() < timeNow
    },
    isSameDate(day) {
      return (new Date(this.meta.year, this.meta.month, day)).getTime() === new Date(this.today.year, this.today.month, this.today.date).getTime()
    }
  }
};
</script>
<style scoped lang="scss">
.table_available_taxi {
  .highlight {
    background: #EB5A8F !important;
    color: white !important;
  }

  .available-regis-btn {
    background: #def1ff;
    cursor: pointer;

    &:hover {
      background: #015CAC !important;
      color: white;
    }
  }
}

.b-t-none {
  border-top: 1px solid #cdcdcd !important;
}

.b-b-none {
  border-bottom: 1px solid #cdcdcd !important;
}

.table-left-fixed {
  border-right: 5px solid #EB5A8F !important;
  border-bottom: 1px solid #a5a5a5 !important;
}

.taxi_type_name {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
